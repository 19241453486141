@keyframes unread-icon-animation {
    from { background: linear-gradient(112deg, rgba(211, 240, 250, 1) 9%, rgba(212, 245, 200, 1) 100%); }
    50% { background: linear-gradient(112deg, rgba(211, 240, 250, 1) 140%, rgba(212, 245, 200, 1) 100%); }
    to { background: linear-gradient(112deg, rgba(211, 240, 250, 1) 9%, rgba(212, 245, 200, 1) 100%); }
}

.unreadIcon {
    position: absolute;
    right: 56px;
    top: 36px;
    font-size: 11px;
    background: linear-gradient(112deg, rgba(211, 240, 250, 1) 9%, rgba(212, 245, 200, 1) 100%);
    border-radius: 16px;
    height: 12px;
    width: 12px;
    animation: unread-icon-animation 1.6s ease-in-out infinite;
}
