.root {
    border-radius: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--mainBgColor);
}

:global(.iframe-mode) .root {
    border-radius: 0;
}

.actions {
    display: flex;
    box-sizing: border-box;
    min-height: 80px;
    padding: 8px 16px;
    margin-top: auto;
}

.title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 16px;
    text-align: center;
    background: var(--boardHeaderBg);
    color: var(--textGrayColor);
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    height: 80px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
}

@media screen and (min-width: 800px) {
    :global(.iframe-mode) .title {
        display: none;
    }
}