body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background-color: var(--bodyBgColor);
}

:global(.iframe-mode) body {
    background-color: var(--mainBgColor);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

:global(.iframe-mode #root) {
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@keyframes msg-blink {
    from { transform: scale(1); }
    50% { transform: scale(0.8); }
    to { transform: scale(1); }
}

@keyframes msg-show {
    from { opacity: 0; }
    50% { opacity: 0 }
    to { opacity: 1; }
}

html {
    --mainBgColor: #fff;
    --mainTextColor: #333;
    --textGrayColor: rgba(0, 0, 0, .35);
    --roomsHeader: rgba(244, 252, 244, 1);
    --iconLight: rgba(0, 0, 0, .37);
    --roomsBorderColor: #fff;
    --textSelectedColor: rgba(25, 118, 210, .8);
    --boardHeaderBg: rgba(244, 252, 244, 1);
    --bodyBgColor: rgba(0, 0, 0, .06);
    --msgBg: #D4F5C8;
    --myMsgBg: #D3F0FA;
    --msgTextColor: #0F1217;
    --msgTail: no-repeat  url(./Assets/tail.svg);
    --myMsgTail: no-repeat  url(./Assets/tail-my.svg);
    --scrollbarThumbBorderColor: #fff;
    --sendBtnBg: rgb(25, 118, 210);
    --sendInputBg: #fff;
    --emojiOpenedIcon: rgba(25, 118, 210, .7);
    --emojiBg: #fff;
    --msgDateWrapperBg: #eee;
    --msgDateText: rgba(0, 0, 0, 0.87);
    --modalDescriptionColor: rgba(0, 0, 0, 0.4);
    --participantsTitleColor: rgba(0, 0, 0, 0.6);
    --participantsTitleRemovedColor: rgb(95, 33, 32);
}

html[data-theme=dark] {
    --mainBgColor: #363846;
    --mainTextColor: #EfEfEf;
    --textGrayColor: rgba(255, 255, 255, .5);
    --roomsHeader: rgba(0, 0, 0, .1);
    --iconLight: rgba(255, 255, 255, .7);
    --roomsBorderColor: #262836;
    --textSelectedColor: #55AD82;
    --boardHeaderBg: rgba(0, 0, 0, .1);
    --bodyBgColor: #1E1E1E;
    --msgBg: #424656;
    --myMsgBg: #499EEA;
    --msgTextColor: #fefefe;
    --msgTail: no-repeat  url(./Assets/tail-dark.svg);
    --myMsgTail: no-repeat  url(./Assets/tail-my-dark.svg);
    --scrollbarThumbBorderColor: #363846;
    --sendBtnBg: #499EEA;
    --sendInputBg: rgba(210, 210, 210, .1);
    --emojiOpenedIcon: #51B28D;
    --emojiBg: #252525;
    --msgDateWrapperBg: rgba(210, 210, 210, .1);
    --msgDateText: #51B28D;
    --modalDescriptionColor: #EfEfEf;
    --participantsTitleColor: #EfEfEf;
    --participantsTitleRemovedColor: #D96863;
}