.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.participants {
    margin-top: 16px;
}

.participants__title {
    font-weight: 600;
    color: var(--participantsTitleColor);
}

.participants__list {
    margin-top: 8px;
}

.chip {
    margin: 4px 2px;
}

.removedParticipants .participants__title{
    color: var(--participantsTitleRemovedColor);
}

.leave {
    padding-left: 8px;
    padding-right: 8px;
}

.description {
    font-size: 12px;
    color: var(--modalDescriptionColor);
    margin-top: 16px;
    text-align: center;
}