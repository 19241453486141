.root {
    max-height: 440px;
    height: 440px;
    overflow: auto;
    box-sizing: border-box;
    background-color: var(--mainBgColor);
    border-bottom: 1px solid var(--roomsBorderColor);
    position: relative;
    padding: 16px;
}

:global(.iframe-mode) .root {
    max-height: none;
    height: auto;
    height: 100%;
}

.root::-webkit-scrollbar {
    width: 8px;
}

.root::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    border: 2px solid var(--scrollbarThumbBorderColor);
}
