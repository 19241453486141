.root {
    position: relative;
    display: flex;
    justify-content: space-between;
}

:global(.iframe-mode) .root {
    height: 100%;
}

.roomsCard {
    background: var(--roomsHeader);
    border-radius: 24px;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
}

:global(.iframe-mode) .roomsCard {
    border-radius: 0;
    box-shadow: none !important;
}

.content {
    border-radius: 24px;
    overflow: hidden;
}

:global(.iframe-mode) .content {
    border-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    height: 100%;
}

.list {
    background-color: var(--mainBgColor);
    max-height: 504px;
    height: 496px;
    overflow: auto;
    padding: 0 32px 24px 0;
    margin-right: -32px;
}

:global(.iframe-mode) .list {
    max-height: none;
    height: 100%;
}

.roomsContainer {
    max-width: 100%;
    display: flex;
    flex-basis: calc(50% - 8px);
}

:global(.iframe-mode) .roomsContainer {
    flex-basis: calc(50%);
}

.boardContainer {
    position: relative;
    width: 100%;
    display: flex;
    flex-basis: calc(50% - 8px);
    transform: translate(calc(100% + 100px));
    opacity: 0;
    will-change: opacity, transform;
}

:global(.iframe-mode) .boardContainer {
    flex-basis: calc(50%);
}

.boardContainer[data-show="true"] {
    transform: translate(0);
    opacity: 1;
    height: 100%;
}

.backBtn {
    position: absolute;
    left: 16px;
    top: 20px;
    width: 32px;
    height: 32px;
    color: rgba(0, 0, 0, .3);
    cursor: pointer;
}

@media screen and (max-width: 800px) {
    .boardContainer {
        position: absolute;
        transition: transform ease-in-out .4s, opacity ease-in-out .15s;
    }

    .root {
        flex-direction: column;
        flex-basis: 100%;
    }

    .roomsContainer {
        flex-basis: 100%;
    }
}
