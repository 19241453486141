.header {
    color: rgba(0, 0, 0, .6);
    position: relative;
    height: 48px;
    text-align: center;
    letter-spacing: 2px;
}

.connectionIndicator {
    color: #EEC453;
    position: absolute;
    top: 6px;
    left: 6px;
    width: 18px;
    height: 18px;
}

.username {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--mainTextColor);
}

.editIcon {
    color: var(--iconLight);
}

.addBtn {
    position: absolute;
    top: 18px;
    right: 16px;
}

