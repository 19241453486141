.root {
    display: flex;
    flex-direction: column;
    min-width: 160px;
    outline: none;
}

.btn {
    padding: 8px 16px;
    display: flex;
    align-items: center;
    color: rgb(204, 204, 204);
    cursor: pointer;
}

.btn:hover {
    background-color: rgba(211, 240, 250, .1);
}

.btn svg:first-child {
    margin-right: 8px;
}

.seenBtn {
    color: var(--mainTextColor);
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 1px -2px, rgb(0, 0, 0, 0.03) 0px 2px 2px 0px, rgba(0, 0, 0, 0.03) 0px 1px 3px 0px;
}

.seenBtn svg {
    color: rgb(140, 140, 140);
}

.readList {
    padding: 4px 0;
    max-height: 150px;
    overflow: auto;
    outline: none;
}

.readList::-webkit-scrollbar {
    width: 8px;
}

.readList::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    border: 2px solid #fff;
}

.readListItem {
    padding: 4px 16px;
    display: flex;
    align-items: center;
}

.readListItem svg {
    margin-right: 8px;
}
