.rootWideScreen {
    padding: 6px 48px;
    padding-right: 16px;
    display: flex;
    justify-content: center;
}

.autocomplete {
    max-width: 400px;
}

.back {
    position: absolute;
    top: 16px;
    left: 2px;
}