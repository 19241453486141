.root {
    background-color: var(--msgBg);
    color: var(--msgTextColor);
    border-radius: 12px;
    min-width: 35%;
    max-width: 90%;
    width: fit-content;
    margin-top: 8px;
    margin-bottom: 8px;
    position: relative;
}

.root::after {
    content: "";
    background: var(--msgTail);
    background-size: 16px;
    width: 16px;
    height: 16px;
    position: absolute;
    z-index: 0;
    left: 0;
    bottom: -5px;
}

.rootMy {
    background-color: var(--myMsgBg);
    margin-left: auto;
    border-bottom-left-radius: 12px;
}

.rootMy::after {
    content: "";
    background: var(--myMsgTail);
    right: 0;
    bottom: -5px;
    left: auto;
}

.content {
    line-height: 1.3;
    display: flex;
    align-items: center;
    padding: 0 8px 6px;
    z-index: 1;
    word-break: break-all;
}

.contentMy {
    padding-bottom: 6px;
    padding-top: 6px;
}

.msg {
    display: flex;
    justify-content: space-between;
}

.actions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    font-size: 12px;
    color: var(--textGrayColor);
    padding: 0 8px;
    margin: auto 0;
}

.actionsTime {
    display: flex;
}

.actionsOk {
    display: flex;
    margin-left: 3px;
    margin-top: 3px;
}

.title {
    display: flex;
    font-size: 12px;
    color: var(--textGrayColor);
    padding: 4px 8px 0;
}

.dateWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.date {
    display: flex;
    font-size: 14px;
    letter-spacing: 1px;
    background-color: var(--msgDateWrapperBg);
    color: var(--msgDateText);
    padding: 3px 12px;
    border-radius: 16px;
    margin: 2px 0;
}

.unreadWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.unread {
    display: flex;
    font-size: 11px;
    letter-spacing: 1px;
    background-color: rgba(211, 240, 250, .4);
    padding: 3px 12px;
    border-radius: 16px;
    margin: 2px 0;
}
