.root {
    position: relative;
    display: flex;
    align-items: center;
}

.menuRoot ul {
    overflow: hidden !important;
}

.openIcon {
    font-size: 28px;
    color: #ccc;
    cursor: pointer;
    transition: color .3s;
}

.openIcon_opened {
    color: var(--emojiOpenedIcon);
}

.card {
    background-color: var(--emojiBg);
    max-width: 208px;
    display: flex;
    flex-wrap: wrap;
    padding: 4px;
    max-height: 104px;
    overflow: hidden;
    overflow-y: scroll;
    outline: none;
    padding-bottom: 12px;
}

.card::before {
    content: "";
    display: flex;
    position: absolute;
    height: 32px;
    background: transparent;
    background: linear-gradient(180deg, var(--emojiBg) 75%, transparent 100%);
    top: -14px;
    left: 0;
    right: 8px;
    z-index: 1;
}

.card::after {
    content: "";
    display: flex;
    position: absolute;
    height: 28px;
    background: transparent;
    background: linear-gradient(0deg, var(--emojiBg) 75%, transparent 100%);
    bottom: -12px;
    left: 0;
    right: 8px;
}

.card::-webkit-scrollbar {
    width: 8px;
}

.card::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    border: 2px solid var(--scrollbarThumbBorderColor);
}


.item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px 4px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    font-size: 32px;
    outline: none;
    border-radius: 24px;
}

.item:after {
    content: "";
    background: rgba(25, 118, 210, .4);
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: all .6s;
    overflow: hidden;
    border-radius: 24px;
  }

  .item:active:after {
    top: 50%;
    bottom: 50%;
    left: 50%;
    right: 50%;
    opacity: 1;
    transition: 0s
}
