.root {
    align-items: flex-start;
}

.rootWideScreen {
    position: fixed;
    top: 16px;
    left: 18px;
    bottom: 0;
    right: 0;
    padding-right: 16px;
    background-color: var(--mainBgColor);
    z-index: 1;
    max-width: 100%;
}

.sendBtnWrapper {
    align-items: center;
    justify-content: flex-end;
    display: flex;
    padding-left: 0;
    padding-top: 14px !important;
    position: relative;
}

.sendBtnWrapper {
    position: relative;
}

.sendBtnInputHack {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 32px;
    max-width: 48px;
    outline: none;
    opacity: 0.1;
    background-color: blue;
    cursor: pointer;
    border: none;
}

.msgField {
    width: 100%;
    margin-left: 8px;
    margin-bottom: 8px;
    background-color: var(--sendInputBg);
    border-radius: 24px;
    color: var(--mainTextColor);
}

.msgField fieldset {
    border-radius: 24px;
    color: var(--mainTextColor);
}
