.root {
    position: absolute;
    max-height: 100px;
    top: 8px;
    left: 8px;
    width: calc(100% - 16px);
    box-sizing: border-box;
    padding: 20px 16px;
    border-radius: 20px;
    transition: opacity ease-in-out .15s, transform ease-in-out .4s;
    background: linear-gradient(112deg, rgba(211, 240, 250, .91) 60%, rgba(212, 245, 200, .91) 100%);
    z-index: 1;
    opacity: 0;
    transform: translateY(calc(-100% - 16px));
    will-change: opacity, transform;
}

.root[data-show="true"] {
    transform: translateY(0);
    opacity: 1;
}

.group {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: rgba(0, 0, 0, .5);
    text-align: left;
}

.msg {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: 8px;
}

.author {
    color: rgba(0, 0, 0, .5);
}
