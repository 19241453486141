.root {
    display: flex;
    position: relative;
    border-bottom: 1px solid var(--roomsBorderColor);
}

.selected {
    color: var(--textSelectedColor) !important;
}

.btn {
    width: 100%;
    justify-content: space-between;
    padding: 16px;
    color: #333;
    text-transform: none;
    border-radius: 0;
}

.labels {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
}

.nameWrapper {
    position: relative;
    display: flex;
    color: var(--mainTextColor);
    max-width: 70%;
}

.roomName {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.names {
    display: flex;
    color: var(--textGrayColor);
    text-align: left;
}

.editBtn {
    opacity: 0.3;
    margin-left: 8px;
    position: absolute;
    top: 40px;
    width: 18px;
    height: 18px;
    right: 16px;
}

.editBtn svg {
    width: 18px;
    height: 18px;
}

.nameAndDate {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.date {
    color: var(--mainTextColor);
    position: absolute;
    right: 16px;
    font-size: 13px;
}
